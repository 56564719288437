<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "App",
  computed: {
    ...mapState({
      imgUrl: (state) => state.weChat.imgUrl,
      desc: (state) => state.weChat.desc,
      title: (state) => state.weChat.title,
    }),
  },
  updated() {
    // this.initWx();
  },
  created() {
    // this.initWx();
  },
  mounted() {},
  methods: {
    isMobile() {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
    },
    initWx() {
      if (/micromessenger/i.test(navigator.userAgent)) {
        const url = window.location.href;
        axios
          .get(
            `https://mainssl.geekpark.net/api/v1/wechat/js_config?request_url=${encodeURIComponent(
              url
            )}`
          )
          .then((response) => {
            const json = response.data;
            const wx = window.wx;
            wx.config({
              debug: false,
              appId: json.appId,
              timestamp: json.timestamp,
              nonceStr: json.nonceStr,
              signature: json.signature,
              jsApiList: ["onMenuShareTimeline", "onMenuShareAppMessage"],
            });

            var title = "Rebuild 2020";
            var desc =
              "科技全明星 · 年中 Talk Gala\n2020.6.19 - 6.21\nB 站独家线上直播";
            var url = window.location.href;
            var imgUrl = "https://summit-img.geekpark.net/r2020/tab_icon.png";

            wx.ready(function () {
              wx.onMenuShareTimeline({
                // 分享朋友圈
                title: title, // 分享标题
                link: url, // 分享链接
                imgUrl: imgUrl, // 分享图标
              });
              wx.onMenuShareAppMessage({
                // 分享给好友
                title: title, // 分享标题
                desc: desc, // 分享描述
                link: url, // 分享链接
                imgUrl: imgUrl, // 分享图标
              });
            });
          });
      }
    },
  },
};
</script>

<style lang="less">
@font-face {
  font-family: ParaTypeStapelExtraBold;
  src: url("https://summit-img.geekpark.net/r2020/ParaType_Stapel_Extra_Bold.otf");
}

#app {
  font-family: "Noto Sans S Chinese", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  backface-visibility: hidden;
}

img {
  user-select: none;
  // pointer-events: none;
}
</style>
