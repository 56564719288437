<template>
  <div class="inno-awards-home">
    <div class="content">
      <div class="top-part">
        <img
          src="https://summit-img.geekpark.net/awards2020/inno_awards-m-desc.svg"
          alt="头图"
        />
      </div>
      <div class="awards-list-title">
        <img
          src="https://summit-img.geekpark.net/awards2020/inno_awards_title.svg"
          alt="获奖名单title"
        />
      </div>
      <div class="awards-list-wrapper">
        <div
          class="award-item-wrapper"
          v-for="(awardDetail, index) in awardsList"
          v-bind:key="index"
        >
          <awardItem v-bind:awardDetail="awardDetail"></awardItem>
        </div>
      </div>
      <div class="inno-logo">
        <img
          src="https://summit-img.geekpark.net/awards2020/inno_awards_powered_by.svg"
        />
      </div>
    </div>
    <div class="footer-part">
      <img src="https://summit-img.geekpark.net/awards2020/geekpark-logo.svg" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import awardItem from "../components/awardItem";

export default {
  name: "Home",
  components: { awardItem },
  data() {
    return {
      awardsList: [],
    };
  },
  created() {},
  mounted() {
    axios
      .get(
        "https://events.geekpark.net/api/v1/reviews/13926fe4-1492-4228-bcda-4bab40e77dd0"
      )
      .then((data) => {
        if (data.data && data.data.awards) {
          data.data.awards.reverse();
        }
        this.awardsList = data.data.awards;
      });
  },
  methods: {},
  beforeDestroy() {},
};
</script>

<style scoped lang="less">
.inno-awards-home {
  .content {
    min-height: 100vh;
    background-color: #01021f;
    background-image: url("https://summit-img.geekpark.net/awards2020/inno_awards_bg.svg"),
      url("https://summit-img.geekpark.net/awards2020/inno_awards_bg_2.svg"),
      url("https://summit-img.geekpark.net/awards2020/inno_awards_bg_2.svg");
    background-size: 100vw 135vw, 100vw auto, 100vw, auto;
    background-position: top center, 0vw 135vw, 0vw 560vw;
    background-repeat: no-repeat;
    .top-part {
      padding-top: 6vw;
      padding-bottom: 10vw;
      img {
        width: 42vw;
        height: auto;
      }
    }
    .awards-list-title {
      padding-bottom: 6vw;
      img {
        width: 30vw;
        height: auto;
      }
    }
    .awards-list-wrapper {
      width: 100%;
      max-width: 940px;
      padding: 0 40px;
      margin: 0 auto;
      padding-bottom: 100px;
      .award-item-wrapper {
        margin-bottom: 180px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .inno-logo {
      padding-bottom: 100px;
      img {
        width: 50vw;
        max-width: 400px;
      }
    }
  }
  .footer-part {
    padding: 6vw 0;
    background-color: #171717;
    text-align: center;
    img {
      width: 34vw;
      max-width: 200px;
    }
  }
}
@media screen and (max-width: 768px) {
  .inno-awards-home {
    .content {
      .top-part {
        img {
          width: 94vw;
        }
      }
      .awards-list-title {
        img {
          width: 80vw;
        }
      }
    }
    .footer-part {
      padding: 10vw 0;
    }
  }
}
</style>
