<template>
  <div class="award-item" v-if="awardTitleSrc">
    <div class="award-title-wrapper">
      <img class="award-title-img" :src="awardTitleSrc" alt="奖项名称" />
      <a class="company-link" :href="awardDetail.brand[0].url" target="_blank">
        <img
          class="company-logo-img"
          v-if="awardDetail.brand.length === 1"
          :src="awardDetail.brand[0].img_list[0]"
          alt="公司logo"
        />
      </a>
      <div
        class="top-line"
        :class="{
          'longer-line': awardDetail.title && awardDetail.title.length > 7,
        }"
      />
      <div
        class="middle-line"
        :class="{
          'longer-line': awardDetail.title && awardDetail.title.length > 7,
        }"
      />
    </div>
    <div class="award-detaill-wrapper">
      <div class="left-line" />
      <div class="detail-wrapper-logo" v-if="awardDetail.brand.length === 1">
        <img
          class="detail-logo"
          alt="logo"
          src="https://summit-img.geekpark.net/awards2020/inno_awards_logo.svg"
        />
        <a
          class="company-link"
          :href="awardDetail.brand[0].url"
          target="_blank"
        >
          <img
            class="company-logo-img"
            v-if="awardDetail.brand.length === 1"
            :src="awardDetail.brand[0].img_list[0]"
            alt="公司logo"
          />
        </a>
        <span class="company-title">{{ awardDetail.brand[0].title }}</span>
      </div>
      <!-- 一个品牌 -->
      <template v-if="awardDetail.brand.length === 1">
        <span class="detail-text">
          {{ awardDetail.brand[0].introduction }}
        </span>
      </template>
      <!-- 一个榜单 -->
      <div class="detail-brand-list" v-else>
        <template>
          <a
            class="brand-list-item-link"
            :href="brandItem.url"
            v-for="(brandItem, index) in awardDetail.brand"
            v-bind:key="index"
            target="_blank"
          >
            <img
              class="company-logo"
              alt="品牌logo"
              :src="brandItem.img_list[0]"
            />
            <span>{{ brandItem.title }}</span>
          </a>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Award-item",
  props: {
    awardDetail: Object,
  },
  data() {
    return {};
  },
  computed: {
    awardTitleSrc() {
      return this.$store.state.awardsNameEmun[this.awardDetail.title];
    },
  },
  mounted() {
    const awardsNameEmun = this.$store.state.awardsNameEmun;
  },
};
</script>

<style lang="less" scoped>
.award-item {
  width: 100%;
  position: relative;
  min-height: 200px;
  a {
    text-decoration: none;
  }
  .award-title-wrapper {
    text-align: left;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-right: 2px solid #a28355;
    .award-title-img {
      width: 42%;
      height: auto;
      position: relative;
      left: -4px;
      bottom: -4px;
    }
    .company-link {
      width: 40%;
      margin-right: 10%;
      .company-logo-img {
        width: 100%;
        height: auto;
      }
    }
    .top-line {
      position: absolute;
      top: 0;
      right: 0;
      width: 78%;
      height: 0;
      border-bottom: 2px solid #a28355;
      &.longer-line {
        width: 63%;
      }
    }
    .middle-line {
      position: absolute;
      top: 100%;
      right: 0;
      width: 54%;
      height: 0;
      border-bottom: 2px solid #a28355;
    }
  }
  .award-detaill-wrapper {
    color: #fff;
    padding: 20px 40px 46px 40px;
    border-bottom: 2px solid #a28355;
    border-right: 2px solid #a28355;
    position: relative;
    .detail-wrapper-logo {
      margin-bottom: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      .detail-logo {
        width: 6%;
        height: auto;
      }
      .company-title {
        padding-left: 10px;
        font-size: 34px;
      }
      .company-link {
        width: 58%;
        margin-left: 10px;
        display: none;
        .company-logo-img {
          width: 100%;
          height: auto;
        }
      }
    }
    .detail-text {
      text-align: left;
      display: inline-block;
    }
    .detail-brand-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .brand-list-item-link {
        width: 18%;
        margin-bottom: 20px;
        .company-logo {
          width: 100%;
        }
        span {
          color: #fff;
        }
      }
    }
    .left-line {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 90%;
      border-left: 2px solid #a28355;
    }
  }
}
@media screen and (max-width: 768px) {
  .award-item {
    .award-title-wrapper {
      .award-title-img {
        width: 90%;
      }
      .company-link {
        display: none;
      }
      .top-line {
        width: 50%;
        &.longer-line {
          width: 18%;
        }
      }
      .middle-line {
        display: none;
      }
    }
    .award-detaill-wrapper {
      .detail-text {
        font-size: 14px;
      }
      .detail-brand-list {
        .brand-list-item-link {
          width: 44%;
          span {
            font-size: 14px;
          }
        }
      }
      .detail-wrapper-logo {
        .detail-logo {
          display: none;
        }
        .company-title {
          display: block;
          font-size: 18px;
          padding-left: 0;
        }
        .company-link {
          display: block;
          margin-left: 0;
          width: 100%;
        }
      }
    }
  }
}
</style>