import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    awardsNameEmun: {
      年度创新产品: "https://summit-img.geekpark.net/awards2020/inno-changxinchanpin.svg",
      年度产品设计: "https://summit-img.geekpark.net/awards2020/inno-chanpinsheji.svg",
      年度产品体验: "https://summit-img.geekpark.net/awards2020/inno-chanpintiyan.svg",
      年度创业新星: "https://summit-img.geekpark.net/awards2020/inno-chuangyexinxing.svg",
      年度技术创新: "https://summit-img.geekpark.net/awards2020/inno-jishuchuangxin.svg",
      年度消费榜样: "https://summit-img.geekpark.net/awards2020/inno-xiaofeibangyang.svg",
      年度系统服务: "https://summit-img.geekpark.net/awards2020/inno-xitongfuwu.svg",
      发展潜力榜单: "https://summit-img.geekpark.net/awards2020/inno-fazhanqianli.svg",
      前沿探索力榜单: "https://summit-img.geekpark.net/awards2020/inno-qianyantansuo.svg",
      社会效率推动力榜单: "https://summit-img.geekpark.net/awards2020/inno-xiaolvtuidong.svg",
      年度创新应用服务: "https://summit-img.geekpark.net/awards2020/inno-chuangxinyingyongfuwu_1.svg",
    },
  },
  mutations: {},
  actions: {},
  modules: {},
});
